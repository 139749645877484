import React, { useEffect } from 'react'
import loadable from '@loadable/component'

const Block = loadable(() => import('./block'))
const Bold = loadable(() => import('./bold'))
const Casual = loadable(() => import('./casual'))
const College = loadable(() => import('./college'))
const Cursive = loadable(() => import('./cursive'))
const Modern = loadable(() => import('./modern'))
const Painted = loadable(() => import('./painted'))
const Retro = loadable(() => import('./retro'))
const Script = loadable(() => import('./script'))
const Serif = loadable(() => import('./serif'))
const SerifEmbos = loadable(() => import('./serif-embos'))
const FontWord = loadable(() => import('./shared/font-file'))
const Signature = loadable(() => import('./signature'))
const Wide = loadable(() => import('./wide'))

const BoldNew = loadable(() => import('./bold-new'))
const Bubble = loadable(() => import('./bubble'))
const Decorative = loadable(() => import('./decorative'))
const Graphic = loadable(() => import('./graphic'))
const Heritage = loadable(() => import('./heritage'))
const RetroSerif = loadable(() => import('./retro-serif'))
const SansSerif = loadable(() => import('./sans-serif'))
const SansSerifEmbos = loadable(() => import('./sans-serif-embos'))

const textTransform = (text, font) => {
  if (font === 'block' || font === 'wide') {
    return text.toUpperCase()
  }
  return text
}

const Word = ({
  mainCol,
  backCol,
  letters,
  fontSize,
  font,
  small,
  print,
  size,
  panelKey,
  maxLetters,
  passRefesh,
  left = false,
  refreshToken,
}) => {
  let isSmall = print === true ? true : small
  const newLetters = textTransform(letters, font)

  useEffect(() => {
    const renderCanvas = document.getElementById('RenderCanvas')
    if (renderCanvas) {
      renderCanvas.style.width = `${size.width}px`
      renderCanvas.style.height = `${size.height}px`
    }
  }, [])

  if (letters.length < 1) {
    return <> </>
  }

  const Components = {
    casual: Casual,
    college: College,
    painted: Painted,
    retro: Retro,
    script: Script,
    serif: Serif,
    'serif-embos': SerifEmbos,
    signature: Signature,
    wide: Wide,
    block: Block,
    bold: Bold,
    cursive: Cursive,
    modern: Modern,
    'bold-new': BoldNew,
    bubble: Bubble,
    decorative: Decorative,
    graphic: Graphic,
    heritage: Heritage,
    'retro-serif': RetroSerif,
    'sans-serif': SansSerif,
    'sans-serif-embos': SansSerifEmbos,
    'zh-ser': FontWord,
    'zh-san': FontWord,
    'zh-block': FontWord,
    'zh-script': FontWord,
    'zh-casual': FontWord,
    'love-letter': FontWord,
  }

  const WordComponent = Components[font]

  if (WordComponent !== undefined) {
    return (
      <>
        {
          font === 'casual' && (
            <link rel="stylesheet" href="/fonts/casual/stylesheet.css" />
          )
        }
        {
          font === 'college' && (
            <link rel="stylesheet" href="/fonts/college/stylesheet.css" />
          )
        }
        {
          font === 'painted' && (
            <link rel="stylesheet" href="/fonts/painted/stylesheet.css" />
          )
        }
        {
          font === 'retro' && (
            <link rel="stylesheet" href="/fonts/retro/stylesheet.css" />
          )
        }
        {
          font === 'script' && (
            <link rel="stylesheet" href="/fonts/script/stylesheet.css" />
          )
        }
        {/* {
          font === 'serif' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/serif.css" />
          )
        } */}
        {/* {
          font === 'serif-embos' && (
            <link rel="stylesheet" href="" />
          )
        } */}
        {
          font === 'signature' && (
            <link rel="stylesheet" href="/fonts/signature/stylesheet.css" />
          )
        }
        {
          font === 'wide' && (
            <link rel="stylesheet" href="/fonts/wide/stylesheet.css" />
          )
        }
        {
          font === 'bold' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/bold.css" />
          )
        }
        {
          font === 'cursive' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/cursive.css" />
          )
        }
        {
          font === 'modern' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/bold-new.css" />
          )
        }
        {
          font === 'bold-new' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/bold-new.css" />
          )
        }
        {
          font === 'bubble' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/bubble.css" />
          )
        }
        {
          font === 'graphic' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/graphic.css" />
          )
        }
        {
          font === 'heritage' && (
            <>
              <link rel="stylesheet" href="/fonts/new-fonts/split/heritage.css" />
              <link rel="stylesheet" href="/fonts/new-fonts/split/heritage-back.css" />
            </>
          )
        }
        {
          font === 'retro-serif' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/retro-serif.css" />
          )
        }
        {
          font === 'sans-serif' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/serif.css" />
          )
        }
        {
          font === 'sans-serif-embos' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/serif.css" />
          )
        }
        {
          font === 'zh-ser' && (
            <link
              href="https://fonts.googleapis.com/css?family=Noto+Sans+SC|Noto+Serif+SC&display=swap"
              rel="stylesheet"
            />
          )
        }
        {
          font === 'love-letter' && (
            <link
              href="https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap"
              rel="stylesheet"
            />
          )
        }
        {
          font === 'zh-san' && (
            <link
              href="https://fonts.googleapis.com/css?family=Noto+Sans+SC|Noto+Serif+SC&display=swap"
              rel="stylesheet"
            />
          )
        }
        {
          font === 'zh-block' && (
            <link
              href="https://fonts.googleapis.com/css?family=Ma+Shan+Zheng&display=swap"
              rel="stylesheet"
            />
          )
        }
        {
          font === 'zh-script' && (
            <link
              href="https://fonts.googleapis.com/css?family=Zhi+Mang+Xing&display=swap"
              rel="stylesheet"
            />
          )
        }
        {/* {
          font === 'zh-casual' && (
            <link rel="stylesheet" href="" />
          )
        } */}
        <link
          href="https://fonts.googleapis.com/css2?family=Caladea:wght@700&family=Work+Sans:wght@600&display=swap"
          rel="stylesheet"
        />
        <WordComponent
          passRefesh={passRefesh}
          maxLetters={maxLetters || 20}
          panelKey={panelKey}
          size={size}
          mainCol={mainCol}
          backCol={backCol}
          letters={newLetters}
          fontSize={fontSize}
          font={font}
          small={isSmall}
          left={left}
          refreshToken={refreshToken}
        />
      </>
    )
  } else {
    return (
      <>
        {
          font === 'casual' && (
            <link rel="stylesheet" href="/fonts/casual/stylesheet.css" />
          )
        }
        {
          font === 'college' && (
            <link rel="stylesheet" href="/fonts/college/stylesheet.css" />
          )
        }
        {
          font === 'painted' && (
            <link rel="stylesheet" href="/fonts/painted/stylesheet.css" />
          )
        }
        {
          font === 'retro' && (
            <link rel="stylesheet" href="/fonts/retro/stylesheet.css" />
          )
        }
        {
          font === 'script' && (
            <link rel="stylesheet" href="/fonts/script/stylesheet.css" />
          )
        }
        {/* {
          font === 'serif' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/serif.css" />
          )
        } */}
        {/* {
          font === 'serif-embos' && (
            <link rel="stylesheet" href="" />
          )
        } */}
        {
          font === 'signature' && (
            <link rel="stylesheet" href="/fonts/signature/stylesheet.css" />
          )
        }
        {
          font === 'wide' && (
            <link rel="stylesheet" href="/fonts/wide/stylesheet.css" />
          )
        }
        {
          font === 'bold' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/bold.css" />
          )
        }
        {
          font === 'cursive' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/cursive.css" />
          )
        }
        {
          font === 'modern' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/bold-new.css" />
          )
        }
        {
          font === 'bold-new' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/bold-new.css" />
          )
        }
        {
          font === 'bubble' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/bubble.css" />
          )
        }
        {
          font === 'graphic' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/graphic.css" />
          )
        }
        {
          font === 'heritage' && (
            <>
              <link rel="stylesheet" href="/fonts/new-fonts/split/heritage.css" />
              <link rel="stylesheet" href="/fonts/new-fonts/split/heritage-back.css" />
            </>
          )
        }
        {
          font === 'retro-serif' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/retro-serif.css" />
          )
        }
        {
          font === 'sans-serif' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/serif.css" />
          )
        }
        {
          font === 'sans-serif-embos' && (
            <link rel="stylesheet" href="/fonts/new-fonts/split/serif.css" />
          )
        }
        {
          font === 'zh-ser' && (
            <link
              href="https://fonts.googleapis.com/css?family=Noto+Sans+SC|Noto+Serif+SC&display=swap"
              rel="stylesheet"
            />
          )
        }
        {
          font === 'zh-san' && (
            <link
              href="https://fonts.googleapis.com/css?family=Noto+Sans+SC|Noto+Serif+SC&display=swap"
              rel="stylesheet"
            />
          )
        }
        {
          font === 'zh-block' && (
            <link
              href="https://fonts.googleapis.com/css?family=Ma+Shan+Zheng&display=swap"
              rel="stylesheet"
            />
          )
        }
        {
          font === 'zh-script' && (
            <link
              href="https://fonts.googleapis.com/css?family=Zhi+Mang+Xing&display=swap"
              rel="stylesheet"
            />
          )
        }
        {/* {
          font === 'zh-casual' && (
            <link rel="stylesheet" href="" />
          )
        } */}
        <link
          href="https://fonts.googleapis.com/css2?family=Caladea:wght@700&family=Work+Sans:wght@600&display=swap"
          rel="stylesheet"
        />

        <FontWord
          passRefesh={passRefesh}
          maxLetters={maxLetters || 20}
          panelKey={panelKey}
          size={size}
          mainCol={mainCol}
          backCol={backCol}
          letters={newLetters}
          fontSize={fontSize}
          font={font}
          small={isSmall}
          left={left}
          refreshToken={refreshToken}
        />
      </>
    )
  }
}

export default Word
