import React from 'react'
import './index.css'

const ProgressBar = ({ total, itemCount, storeLocale }) => {
  const threshold = 400
  let totalCount = 0
  
  if (itemCount) {
    totalCount = itemCount.reduce(
      (accumulator, a) => accumulator + (a[1].includes('SET') ? 2 : 1 * a[0]),
      0
    )
  }

  const currencySign = storeLocale === 'en-GB' ? '£' : '$'
  const progress = Math.min((total / threshold) * 100, 100)

  return (
    <div className="progress-bar">
      <div
        className="filler"
        style={{
          width: `${progress}%`,
          maxWidth: '100%',
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          height: '100%',
          textAlign: 'center',
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            color: '#5F3E1A',
            textShadow: '-1px 1px 5px rgb(0 0 0 / 20%)',
            fontSize: 12,
            fontWeight: 600,
          }}
        >
          {total < threshold ? (
            <>Spend {currencySign}{(threshold - total).toFixed(2)} more to get a free gift!</>
          ) : (
            <>Congratulations! You've qualified for a free gift!</>
          )}
        </span>
      </div>
    </div>
  )
}

export default ProgressBar